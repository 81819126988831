import _ from 'lodash';
import ApiBaseService from '@/core/ApiBaseService';
import authService from '@/services/server/auth-service/authService';
import Product from '@/entities/Product';

export default class BackOfficeService extends ApiBaseService {
    baseUrl: string;

    constructor(baseUrl) {
      super();
      this.baseUrl = `${baseUrl}/products`;
    }

    async getProducts(): Promise<Product[]> {
      const { data } = await this.httpGet(
        this.baseUrl,
        {
          headers: authService.getAuthHeaders({ withAd: true }),
        },
      );

      if (data?.data?.length) {
        return data.data.map((product) => new Product(product));
      }
      return [];
    }

    async createProduct(newProduct): Promise<Product|null> {
      const { data } = await this.httpPost(
        this.baseUrl,
        {
          ...newProduct,
        },
        {
          headers: authService.getAuthHeaders({ withAd: true }),
        },
      );

      if (data?.data) {
        const product = new Product(data.data);
        return product;
      }
      return null;
    }

    async updateProduct(newProduct): Promise<Product|null> {
      const { data } = await this.httpPatch(
        `${this.baseUrl}/${newProduct.id}`,
        {
          ...newProduct,
        },
        {
          headers: authService.getAuthHeaders({ withAd: true }),
        },
      );

      if (data?.data) {
        const product = new Product(data.data);
        return product;
      }
      return null;
    }

    async sortProducts(sortedProducts): Promise<Product[]> {
      const { data } = await this.httpPatch(
        this.baseUrl,
        {
          products: sortedProducts,
        },
        {
          headers: authService.getAuthHeaders({ withAd: true }),
        },
      );

      if (data?.data?.length) {
        return data.data.map((product) => new Product(product));
      }
      return [];
    }

    async importProductsFromExcelFile(formData: FormData): Promise<{ isImported: boolean }> {
      const { data } = await this.httpPost(
        `${this.baseUrl}/import/xlsx`,
        formData,
        {
          headers: authService.getAuthHeaders({ withAd: true }),
        },
      );
      return data;
    }

    async getDownloadProductsExcelUrl() {
      const idToken = authService.getAuthHeaders({ withAd: true }).Authorization;
      return `${this.baseUrl}/export/xlsx?&userToken=${idToken}`;
    }
}
