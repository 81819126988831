import store from '@/store';

const getShopToken = () => (store.getters['shopAuth/authenticatedShopToken']);

const getBearerToken = () => (store.getters['auth/authenticatedUserIdToken']);

export default {
  getAuthHeaders({ withAd } = { withAd: false }) {
    return {
      // 'x-api-key': 'admin',
      'shop-token': getShopToken(),
      ...(withAd && { Authorization: `Bearer ${getBearerToken()}` }),
    };
  },
};
