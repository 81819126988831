export default class StoreRayonConfig {
  id: string;

  storeId: string;

  monday: number | null;

  tuesday: number | null;

  wednesday: number | null;

  thursday: number | null;

  friday: number | null;

  saturday: number | null;

  sunday: number | null;

  productId: string;

  product: {
    id: string;
    name: string;
    family: string;
    productSource: string;
    quantityPerPlate: number | null;
  }

  constructor(data: any) {
    this.id = data.id;
    this.storeId = data.storeId;
    this.monday = data.monday;
    this.tuesday = data.tuesday;
    this.wednesday = data.wednesday;
    this.thursday = data.thursday;
    this.friday = data.friday;
    this.saturday = data.saturday;
    this.sunday = data.sunday;
    this.productId = data.product?.id;
    this.product = data.product;
  }

  isPlating(): boolean {
    if (!this.product) { return false; }

    return (this.product?.quantityPerPlate || 0) > 1;
  }

  plateToQuantity(plate: number): number {
    if (!this.isPlating()) {
      return plate;
    }

    return plate * (this.product?.quantityPerPlate || 1);
  }

  quantityToPlate(quantity: number | null): null | number {
    if (!quantity) {
      return null;
    }
    if (!this.isPlating()) {
      return quantity;
    }

    if (!this.product?.quantityPerPlate) {
      return 0;
    }

    return Math.ceil(quantity / this.product?.quantityPerPlate || 1);
  }

  convertAllQuantityToPlate() {
    this.monday = this.quantityToPlate(this.monday);
    this.tuesday = this.quantityToPlate(this.tuesday);
    this.wednesday = this.quantityToPlate(this.wednesday);
    this.thursday = this.quantityToPlate(this.thursday);
    this.friday = this.quantityToPlate(this.friday);
    this.saturday = this.quantityToPlate(this.saturday);
    this.sunday = this.quantityToPlate(this.sunday);
  }
}
