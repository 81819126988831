export default class StorePrevision {
  id: string;

  storeId: string;

  previsionDate: string;

  lastWeekMorningRevenue: string;

  lastWeekAfternoonRevenue: string;

  lastWeekRevenue: string;

  lastWeekDiscountedRevenue: string;

  expectedRevenue: string;

  morningRevenue: string;

  lastWeekRevenueDiff: string;

  isPrevisionSaved: string;

  isMidDayCheckInSaved: string;

  constructor(data) {
    this.id = data.id;
    this.storeId = data.storeId ?? null;
    this.previsionDate = data.previsionDate ?? null;
    this.lastWeekMorningRevenue = data.lastWeekMorningRevenue ?? null;
    this.lastWeekAfternoonRevenue = data.lastWeekAfternoonRevenue ?? null;
    this.lastWeekRevenue = data.lastWeekRevenue ?? null;
    this.lastWeekDiscountedRevenue = data.lastWeekDiscountedRevenue ?? null;
    this.expectedRevenue = data.expectedRevenue ?? null;
    this.morningRevenue = data.morningRevenue ?? null;
    this.lastWeekRevenueDiff = data.lastWeekRevenueDiff ?? null;
    this.isPrevisionSaved = data.isPrevisionSaved ?? null;
    this.isMidDayCheckInSaved = data.isMidDayCheckInSaved ?? null;
  }
}
