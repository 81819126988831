import axios from 'axios';

export default class ApiBaseService {
  constructor() {
    this.httpClient = axios;
    this.userToken = '';
  }

  setUserToken(token) {
    this.userToken = token;
  }

  resetUserToken() {
    this.setUserToken();
  }

  httpGet(url, options) {
    let optionsWithUserToken = { headers: {} };
    optionsWithUserToken.headers.Authorization = this.userToken;
    optionsWithUserToken.headers['user-token'] = this.userToken;
    optionsWithUserToken = { ...optionsWithUserToken, ...options };
    return this.httpClient.get(url, optionsWithUserToken);
  }

  httpPut(url, data, options) {
    let optionsWithUserToken = { headers: {} };
    optionsWithUserToken.headers.Authorization = this.userToken;
    optionsWithUserToken.headers['user-token'] = this.userToken;
    optionsWithUserToken = { ...optionsWithUserToken, ...options };
    return this.httpClient.put(url, data, optionsWithUserToken);
  }

  httpPost(url, data, options) {
    let optionsWithUserToken = { headers: {} };
    optionsWithUserToken.headers.Authorization = this.userToken;
    optionsWithUserToken.headers['user-token'] = this.userToken;
    optionsWithUserToken = { ...optionsWithUserToken, ...options };
    return this.httpClient.post(url, data, optionsWithUserToken);
  }

  httpPatch(url, data, options) {
    let optionsWithUserToken = { headers: {} };
    optionsWithUserToken.headers.Authorization = this.userToken;
    optionsWithUserToken.headers['user-token'] = this.userToken;
    optionsWithUserToken = { ...optionsWithUserToken, ...options };
    return this.httpClient.patch(url, data, optionsWithUserToken);
  }

  httpDelete(url, options) {
    let optionsWithUserToken = { headers: {} };
    optionsWithUserToken.headers.Authorization = this.userToken;
    optionsWithUserToken.headers['user-token'] = this.userToken;
    optionsWithUserToken = { ...optionsWithUserToken, ...options };
    return this.httpClient.delete(url, optionsWithUserToken);
  }
}
