import StoreRayonConfigService from '@/services/server/store-rayon-config/store-rayon-config-service';
import RefreshMorningSalesService from '@/services/server/refresh-morning-sales/refresh-morning-sales-service';
import WeatherService from '@/services/server/weather-service/weather-service';
import authService from './auth-service/authService';
import ClaimService from './claim-service/claim-service';
import StorePrevisionService from './prevision-service/store-prevision-service';
import ProductPrevisionService from './prevision-service/product-prevision-service';
import BackOfficeService from './back-office-service/back-office-service';

const baseUrl = `${process.env.VUE_APP_SERVER_API_BASE_URL}`;
const storePrevisionService = new StorePrevisionService(baseUrl);

export default {
  authService,
  claimService: new ClaimService(baseUrl),
  storePrevisionService: new StorePrevisionService(baseUrl),
  productPrevisionService: new ProductPrevisionService(baseUrl, storePrevisionService),
  backOfficeService: new BackOfficeService(baseUrl),
  storeRayonConfigService: new StoreRayonConfigService(baseUrl),
  refreshMorningSalesService: new RefreshMorningSalesService(baseUrl),
  weatherService: new WeatherService(baseUrl),
};
