import dayjs from 'dayjs';
import ApiBaseService from '@/core/ApiBaseService';
import authService from '@/services/server/auth-service/authService';
import ProductPrevision from '@/entities/ProductPrevision';

export default class ProductPrevisionService extends ApiBaseService {
    baseUrl: string;

    storePrevisionService: any;

    constructor(baseUrl, storePrevisionService) {
      super();
      this.baseUrl = `${baseUrl}/product-previsions`;
      this.storePrevisionService = storePrevisionService;
    }

    async getProductPrevisions(storeId, previsionDate) {
      const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
      const { data } = await this.httpGet(
        `${this.baseUrl}?storeId=${storeId}&previsionDate=${formattedDate}`,
        {
          headers: authService.getAuthHeaders(),
        },
      );
      if (data?.data?.length) {
        return data.data.map((productPrevision) => {
          const product = new ProductPrevision(productPrevision);
          product.convertPlannedQuantityToPlatingIfNeededForFrontend();
          return product;
        });
      }
      return [];
    }

    async getProductPrevisionsWithExpectedRevenue(storeId, previsionDate, expectedRevenue) {
      await this.storePrevisionService.initializeStorePrevisionsWithExpectedRevenue(
        storeId, previsionDate, expectedRevenue,
      );
      const allProductPrevisions = await this.getProductPrevisions(storeId, previsionDate);
      return allProductPrevisions;
    }

    async initStoreAndGetProductPrevisionsWithExpectedRevenue(storeId, previsionDate, expectedRevenue) {
      const allProductPrevisions = await this.getProductPrevisionsWithExpectedRevenue(
        storeId, previsionDate, expectedRevenue,
      );
      return allProductPrevisions;
    }

    async sendProductPlannedAndOpeningQuantities(storeId, previsionDate, products) {
      const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
      const previsions = products.map((product) => {
        const plannedQuantity = (product.plannedQuantity ?? null) === '' ? null : product.plannedQuantity * product.quantityPerPlate;
        const openingQuantity = (product.openingQuantity ?? null) === '' ? null : product.openingQuantity * product.quantityPerPlate;
        return {
          productId: product.productId,
          plannedQuantity,
          openingQuantity,
        };
      });
      const { data } = await this.httpPost(
        `${this.baseUrl}/previsions?storeId=${storeId}&previsionDate=${formattedDate}`,
        {
          previsions,
        },
        {
          headers: authService.getAuthHeaders(),
        },
      );
      if (data?.data?.length) {
        return data.data.map((productPrevision) => {
          const product = new ProductPrevision(productPrevision);
          return product;
        });
      }
      return [];
    }

    async sendProductMorningSales(storeId, previsionDate, products) {
      const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
      const morningSales = products.map((product) => {
        const morningSoldQuantity = (product.morningSoldQuantity ?? null) === '' ? null : product.morningSoldQuantity;
        const stockQuantity = (product.stockQuantity ?? null) === '' ? null : product.stockQuantity;
        const relaunchQuantity = (product.relaunchQuantity ?? null) === '' ? null : product.relaunchQuantity;
        return {
          productId: product.productId,
          morningSoldQuantity,
          stockQuantity,
          relaunchQuantity,
        };
      });
      const { data } = await this.httpPost(
        `${this.baseUrl}/morningSales?storeId=${storeId}&previsionDate=${formattedDate}`,
        {
          morningSales,
        },
        {
          headers: authService.getAuthHeaders(),
        },
      );
      if (data?.data?.length) {
        return data.data.map((productPrevision) => new ProductPrevision(productPrevision));
      }
      return [];
    }
}
