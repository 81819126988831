import ApiBaseService from '@/core/ApiBaseService';
import authService from '@/services/server/auth-service/authService';
import dayjs from 'dayjs';
import type { WeatherList } from '@/services/server/weather-service/types/weather';
import Weather from '@/entities/Weather';

export default class WeatherService extends ApiBaseService {
  baseUrl: string;

  constructor(baseUrl) {
    super();
    this.baseUrl = `${baseUrl}/weather-forecast`;
  }

  async getWeather(storeId : string, previsionDate: string): Promise<WeatherList | null> {
    const formattedPrevisionDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpGet(
      `${this.baseUrl}/by-shop-code-and-date?shopCode=${storeId}&previsionDate=${formattedPrevisionDate}`,
      {
        headers: authService.getAuthHeaders(),
      },
    );

    if (data) {
      return {
        old: data?.old ? new Weather(data?.old) : null,
        new: data?.new ? new Weather(data?.new) : null,
      };
    }
    return null;
  }
}
