export default class ProductPrevision {
  id: string;

  storeId: string;

  productSource: string;

  productId: string;

  plu: string;

  previsionDate: string;

  productName: string;

  family: string;

  lastWeekDate: string;

  lastYearDate: string;

  lastYearSoldQuantity: string;

  lastWeekSoldQuantity: string;

  lastWeekMorningSoldQuantity: string;

  lastWeekAfternoonSoldQuantity: string;

  lastWeekDiscountSoldQuantity: string;

  suggestedQuantity: string;

  plannedQuantity: number;

  openingQuantity: number;

  afternoonSoldQuantity: string;

  lastWeekAfternoonSoldDiff: string;

  midDayNeeds: string;

  stockQuantity: string;

  relaunchQuantity: string;

  quantityPerPlate: number;

  isRequired: string;

  minQuantity: string;

  morningSoldQuantity: string;

  unitPrice: string;

  constructor(data) {
    this.id = data.id;
    this.storeId = data.storeId ?? null;
    this.productSource = data.productSource ?? null;
    this.productId = data.productId ?? null;
    this.plu = data.plu ?? null;
    this.previsionDate = data.previsionDate ?? null;
    this.productName = data.productName ?? null;
    this.family = data.family ?? null;
    this.lastWeekDate = data.lastWeekDate ?? null;
    this.lastYearDate = data.lastYearDate ?? null;
    this.lastYearSoldQuantity = data.lastYearSoldQuantity ?? null;
    this.lastWeekSoldQuantity = data.lastWeekSoldQuantity ?? null;
    this.lastWeekMorningSoldQuantity = data.lastWeekMorningSoldQuantity ?? null;
    this.lastWeekAfternoonSoldQuantity = data.lastWeekAfternoonSoldQuantity ?? null;
    this.lastWeekDiscountSoldQuantity = data.lastWeekDiscountSoldQuantity ?? null;
    this.suggestedQuantity = data.suggestedQuantity ?? null;
    this.plannedQuantity = data.plannedQuantity ?? null;
    this.openingQuantity = data.openingQuantity ?? null;
    this.afternoonSoldQuantity = data.afternoonSoldQuantity ?? null;
    this.lastWeekAfternoonSoldDiff = data.lastWeekAfternoonSoldDiff ?? null;
    this.midDayNeeds = data.midDayNeeds ?? null;
    this.stockQuantity = data.stockQuantity ?? null;
    this.relaunchQuantity = data.relaunchQuantity ?? null;
    this.quantityPerPlate = data.quantityPerPlate ?? null;
    this.isRequired = data.isRequired ?? null;
    this.minQuantity = data.minQuantity ?? null;
    this.morningSoldQuantity = data.morningSoldQuantity ?? null;
    this.unitPrice = data.unitPrice ?? null;
  }

  public convertPlannedQuantityToPlatingIfNeededForFrontend() {
    if (this.quantityPerPlate >= 1) {
      if (this.plannedQuantity) {
        this.plannedQuantity = Math.ceil(this.plannedQuantity / this.quantityPerPlate);
      }
      if (this.openingQuantity) {
        this.openingQuantity = Math.ceil(this.openingQuantity / this.quantityPerPlate);
      }
    }
  }

  public isPlating() {
    return this.quantityPerPlate && this.quantityPerPlate > 1;
  }

  public numberToPlateString(nb: number | string) {
    if (typeof nb === 'string') {
      return nb;
    }
    if (!this.isPlating()) {
      return `${nb}`;
    }
    const platesCount = Math.ceil(nb / this.quantityPerPlate);
    return `${platesCount} (${nb})`;
  }

  public convertToPlateQuantityIfNeeded(nb: number) {
    if (!this.isPlating()) {
      return nb;
    }
    return Math.ceil(nb / this.quantityPerPlate);
  }

  public convertToPlateQuantity(nb: number) {
    return Math.ceil(nb / this.quantityPerPlate);
  }
}
