export default class Weather {
  id: string;
  shopCode: string;
  city: string;
  code: string;
  date: string;
  dateInTimestamp: number;
  maxTemperature : number;
  minTemperature: number;
  weatherId: number;
  weatherMain: string;
  weatherDescription: string;
  weatherIcon: string;

  constructor(data: Weather) {
    this.id = data.id;
    this.shopCode = data.shopCode;
    this.city = data.city;
    this.code = data.code;
    this.date = data.date;
    this.dateInTimestamp = data.dateInTimestamp;
    this.maxTemperature = data.maxTemperature;
    this.minTemperature = data.minTemperature;
    this.weatherId = data.weatherId;
    this.weatherMain = data.weatherMain;
    this.weatherDescription = data.weatherDescription;
    this.weatherIcon = data.weatherIcon;
  }
}
