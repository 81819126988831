export enum ProductSource {
  BAKERY = 'BAKERY',
  PREPARATION = 'PREPARATION',
}

export type Article = {
  id: string;
  plu: number;
  name: string;
  quantity: number;
  product: any;
}

export default class Product {
  id: string;
  family: string;
  subfamily: string;
  previsionFamily: string;
  pdfFamily: string;
  name: string;
  isActive: boolean;
  isRequired: boolean;
  minQuantity: number;
  productSource: ProductSource;
  equivalenceRate: number | null;
  equivalenceProductName: number | null;
  quantityPerPlate: number;
  sortingPosition: number;
  articles: Article[];

  constructor(data: Product) {
    this.id = data.id;
    this.family = data.family;
    this.subfamily = data.subfamily;
    this.previsionFamily = data.previsionFamily;
    this.pdfFamily = data.pdfFamily;
    this.name = data.name;
    this.isActive = data.isActive;
    this.isRequired = data.isRequired;
    this.minQuantity = data.minQuantity;
    this.productSource = data.productSource;
    this.quantityPerPlate = data.quantityPerPlate;
    this.sortingPosition = data.sortingPosition;
    this.equivalenceProductName = data.equivalenceProductName;
    this.equivalenceRate = data.equivalenceRate;
    this.articles = data.articles;
  }
}
