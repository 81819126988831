import dayjs from 'dayjs';
import ApiBaseService from '@/core/ApiBaseService';
import authService from '@/services/server/auth-service/authService';
import StorePrevision from '@/entities/StorePrevision';

export default class StorePrevisionService extends ApiBaseService {
  baseUrl: string;

  constructor(baseUrl) {
    super();
    this.baseUrl = `${baseUrl}/store-previsions`;
  }

  async regenerateStoreAndProductPrevisions(storeId: string, previsionDate: string | Date) {
    try {
      const formattedPrevisionDate = dayjs(previsionDate).format('YYYY-MM-DD');
      await this.httpPost(
        `${this.baseUrl}/regenerate?storeId=${storeId}&previsionDate=${formattedPrevisionDate}`,
        {},
        {
          headers: authService.getAuthHeaders(),
        },
      );
      return null;
    } catch (e) {
      if (e?.response?.data?.status) {
        throw new Error(`REGENERATE_ERROR: ${e.response.data.status}`);
      }
      throw new Error('REGENERATE_ERROR: EXCEPTION');
    }
  }

  async getStorePrevisions(storeId, previsionDate) {
    const formattedPrevisionDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpGet(
      `${this.baseUrl}?storeId=${storeId}&previsionDate=${formattedPrevisionDate}`,
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async initializeStorePrevisionsWithExpectedRevenue(storeId, previsionDate, expectedRevenue) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/previsions.initialize?storeId=${storeId}&previsionDate=${formattedDate}`,
      {
        expectedRevenue,
      },
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async validateAndClosePrevisions(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/previsions.close?storeId=${storeId}&previsionDate=${formattedDate}`,
      null,
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async openPrevisions(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/previsions.open?storeId=${storeId}&previsionDate=${formattedDate}`,
      null,
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async initializeStorePrevisionsWithMorningRevenue(storeId, previsionDate, morningRevenue) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/midDayCheckIn.initialize?storeId=${storeId}&previsionDate=${formattedDate}`,
      {
        morningRevenue,
      },
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async validateAndCloseMidday(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/midDayCheckIn.close?storeId=${storeId}&previsionDate=${formattedDate}`,
      null,
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async openMidday(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/midDayCheckIn.open?storeId=${storeId}&previsionDate=${formattedDate}`,
      null,
      {
        headers: authService.getAuthHeaders(),
      },
    );
    if (data.data) {
      return new StorePrevision(data.data);
    }
    return null;
  }

  async getDownloadPreparateurMainSheetUrl(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const shopToken = authService.getAuthHeaders()['shop-token'];
    return `${this.baseUrl}/preparationSheet.generate?storeId=${storeId}&previsionDate=${formattedDate}&shopToken=${shopToken}`;
  }

  async getDownloadBoulangerMainSheetUrl(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const shopToken = authService.getAuthHeaders()['shop-token'];
    return `${this.baseUrl}/bakerySheet.generate?storeId=${storeId}&previsionDate=${formattedDate}&shopToken=${shopToken}`;
  }

  async getDownloadPreparateurRecapSheetUrl(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const shopToken = authService.getAuthHeaders()['shop-token'];
    return `${this.baseUrl}/preparationRecapSheet.generate?storeId=${storeId}&previsionDate=${formattedDate}&shopToken=${shopToken}`;
  }

  async getDownloadBoulangerRecapSheetUrl(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const shopToken = authService.getAuthHeaders()['shop-token'];
    return `${this.baseUrl}/bakeryRecapSheet.generate?storeId=${storeId}&previsionDate=${formattedDate}&shopToken=${shopToken}`;
  }

  async getDownloadPreparateurSupportStockSheetUrl(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const shopToken = authService.getAuthHeaders()['shop-token'];
    return `${this.baseUrl}/preparationSupportStockSheet.generate?storeId=${storeId}&previsionDate=${formattedDate}&shopToken=${shopToken}`;
  }

  async getDownloadBoulangerSupportStockSheetUrl(storeId, previsionDate) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const shopToken = authService.getAuthHeaders()['shop-token'];
    return `${this.baseUrl}/bakerySupportStockSheet.generate?storeId=${storeId}&previsionDate=${formattedDate}&shopToken=${shopToken}`;
  }
}
