import ApiBaseService from '@/core/ApiBaseService';
import dayjs from 'dayjs';
import authService from '@/services/server/auth-service/authService';

export default class RefreshMorningSalesService extends ApiBaseService {
  baseUrl: string;

  constructor(baseUrl) {
    super();
    this.baseUrl = `${baseUrl}/midday-checkin`;
  }

  async refreshMorningSales(storeId : string, previsionDate: string) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const response = await this.httpPost(
      `${this.baseUrl}/refresh`,
      { storeId, previsionDate: formattedDate },
      {
        headers: authService.getAuthHeaders(),
      },
    );
  }

  async refreshMorningTurnover(storeId : string, previsionDate: string) {
    const formattedDate = dayjs(previsionDate).format('YYYY-MM-DD');
    const { data } = await this.httpPost(
      `${this.baseUrl}/morning-turnover`,
      { storeId, previsionDate: formattedDate },
      {
        headers: authService.getAuthHeaders(),
      },
    );
  }
}
