export default class Prevision {
  constructor(data) {
    this.id = data.id;
    this.shopId = data.shopId ?? null;
    this.shopName = data.shopName ?? null;
    this.shopConcept = data.shopConcept ?? null;
    this.storeUserFullName = data.userFullName ?? null;
    this.deliveryDate = data.deliveryDate ?? null;
    this.deliveryTime = data.deliveryTime ?? null;
    this.suppliers = data.suppliers ?? null;
    this.supplier = data.supplier ?? null;
    this.deliverySupplierName = data.deliverySupplierName ?? null;
    this.deliveryFormNumber = data.deliveryFormNumber ?? null;
    this.deliveryProductBatchNumber = data.productBatchNumber ?? null;
    this.supplierProducts = data.supplierProducts ?? null;
    this.product = data.product ?? null;
    this.deliveryProductName = data.deliveryProductName ?? null;
    this.deliveryProductQuantity = data.productQuantity ?? null;
    this.deliveryQuantityUnitName = data.deliveryQuantityUnitName ?? null;
    this.deliveryFormFile = data.deliveryFormFile ?? null;
    this.reasonListOfReasons = data.reasons ?? null;
    this.reasonDescription = data.reasonDescription ?? null;
    this.reasonProofFiles = data.reasonProofFiles ?? null;
    this.actionSelectedAction = data.action ?? null;
    this.actionDescription = data.actionDescription ?? null;
    this.createdAt = data.createdAt ?? null;
    this.status = data.status ?? null;
    this.file = data.file ?? null;
  }
}
export function transformFormToAPI(data) {
  return {
    id: data.id,
    shopId: data.shopId,
    shopName: data.shopName,
    userFullName: data.storeUserFullName,
    deliveryDate: data.deliveryDate,
    deliveryTime: data.deliveryTime,
    supplierId: data.supplier.id,
    deliveryFormNumber: data.deliveryFormNumber,
    productBatchNumber: data.deliveryProductBatchNumber,
    productId: data.product.id,
    productQuantity: Number(data.deliveryProductQuantity),
    quantityUnitName: data.deliveryQuantityUnitName,
    deliveryFormFile: data.deliveryFormFile,
    reasons: data.reasonListOfReasons,
    reasonDescription: data.reasonDescription,
    reasonProofFiles: data.reasonProofFiles,
    action: data.actionSelectedAction,
    actionDescription: data.actionDescription,
    status: data.status,
  };
}

export function transformAPIDataToHistory(data) {
  return {
    id: data.id,
    deliveryFormNumber: data.deliveryFormNumber,
    deliveryDate: data.deliveryDate,
    deliverySupplierName: data.supplier.name,
    deliveryProductName: data.product.name,
    deliveryProductQuantity: Number(data.productQuantity),
    deliveryQuantityUnitName: data.quantityUnitName,
    status: data.status,
    createdAt: data.createdAt,
  };
}
