import ApiBaseService from '@/core/ApiBaseService';
import StoreRayonConfig from '@/entities/StoreRayonConfig';
import { OpeningRayon } from '@/services/server/store-rayon-config/store-rayon-types';
import authService from '@/services/server/auth-service/authService';

export default class StoreRayonConfigService extends ApiBaseService {
  baseUrl: string;

  constructor(baseUrl) {
    super();
    this.baseUrl = `${baseUrl}/store-config`;
  }

  async getOpeningRayonConfig(storeId: string, type: 'preparateur' | 'boulanger'): Promise<StoreRayonConfig[]> {
    const res = await this.httpGet(`${this.baseUrl}/${storeId}/opening-rayon/${type}`, {
      headers: authService.getAuthHeaders({ withAd: true }),
    });
    if (!res.data) {
      return [];
    }
    return (res.data as OpeningRayon[]).map((item) => {
      const obj = new StoreRayonConfig(item);
      obj.convertAllQuantityToPlate();
      return obj;
    });
  }

  async updateOpeningRayonConfig(storeId: string, items: StoreRayonConfig[]): Promise<StoreRayonConfig[]> {
    const stringToNull = (value: any) => {
      if (typeof value === 'string' && value === '') {
        return null;
      }
      return value;
    };

    const requestItems = items.map((item) => {
      const tmp = { ...item };
      ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((day) => {
        tmp[day] = stringToNull(tmp[day]);
        if (item.isPlating()) {
          tmp[day] = item.plateToQuantity(tmp[day]);
        }
      });
      return tmp;
    });

    const res = await this.httpPut(`${this.baseUrl}/${storeId}/opening-rayon`, { items: requestItems }, {
      headers: authService.getAuthHeaders({ withAd: true }),
    });
    if (!res.data) {
      return [];
    }
    return (res.data as OpeningRayon[]).map((item) => new StoreRayonConfig(item));
  }
}
