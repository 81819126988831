import ApiBaseService from '@/core/ApiBaseService';
import Claim, { transformAPIDataToHistory } from '@/entities/Claim';
import authService from '@/services/server/auth-service/authService';

export default class ClaimService extends ApiBaseService {
  constructor(baseUrl) {
    super();
    this.baseUrl = baseUrl;
  }

  async getClaimsHistory(shopId) {
    const { data } = await this.httpGet(`${this.baseUrl}/claims?shopId=${shopId}&include=supplier,shop,product`,
      {
        headers: authService.getAuthHeaders(),
      });
    if (data.data) {
      return data.data.map((claimData) => transformAPIDataToHistory(claimData));
    }
    return [];
  }

  async getClaimById(claimId) {
    const { data } = await this.httpPost(`${this.baseUrl}/claims/${claimId}`, {
      headers: authService.getAuthHeaders(),
    });
    if (data.data) {
      return new Claim(data.data);
    }
    return null;
  }

  async createClaim(claim) {
    const { data } = await this.httpPost(`${this.baseUrl}/claims`, claim, {
      headers: authService.getAuthHeaders(),
    });
    return new Claim(data.data);
  }
}
